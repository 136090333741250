/* ----------------------------- modal userlist onclick--------------*/ 
.content-modal-list-users {
    display: -ms-grid;
    display: grid;
    gap: 1em;
    padding: 1em;
}

.content-modal-list-users > * {
    text-decoration: none;
    color: var(--color-blue);
    border: transparent;
}

.img-name-modal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 0.5em;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.modal {
    border-radius: 18px;
    margin: auto;
    width: 380px;
    border: 0;
    -webkit-box-shadow: 0 0 1em rgb(0 0 0 / .3);
            box-shadow: 0 0 1em rgb(0 0 0 / .3);
}

.modalListRoom {
    border-radius: 18px;
    margin: auto;
    width: 680px;
    border: 0;
    -webkit-box-shadow: 0 0 1em rgb(0 0 0 / .3);
            box-shadow: 0 0 1em rgb(0 0 0 / .3);
}

.modalListRoom::backdrop {
    background: linear-gradient(45deg, red, blue);
    /*background:  rgb(207 220 242 / 1);*/
    opacity: .4;
}

.modal::backdrop {
    background: linear-gradient(45deg, red, blue);
    /*background:  rgb(207 220 242 / 1);*/
    opacity: .4;
}


.modalHeader {
    color: white;
    height: 55px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    background-color: #3F4248;
    justify-content: center;
    align-items: center;
}

ul {
    list-style-type: none;
  }


/* -----------------------modal userlist onclick -------------------*/ 

li a.dropbtn-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
}

ul#list-users{
    padding:5px;
}
.cs-conversation__name{
   text-align: left;
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected{
    color:#1677ff;
}
.ant-tabs-top{
    margin:0 0 0px 0
  }

  .ant-tabs-top >.ant-tabs-nav{
    margin:0px
  }

