
.ant-form-item .ant-form-item-label > label{
   // color: black;
}

.ant-form-item{
    margin-bottom: 5px;
}


.custom-form-item .ant-form-item-label {
    text-align: left;
}

