.mon-element {
   
    transition: border-color 0.3s;
    padding: 2px;
}

.border {
    border-width: 10px;
    border: 1px solid grey;
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #333;
}

/* Styles au survol */
.mon-element:hover {
    border-color: #ff0000;
}