body {
  color: white;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-form-item .ant-form-item-label>label {
  color: white;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-form-item .ant-form-item-label {
  text-align: start;
}

.boutonSendMessage {
  border-radius: 0px 25px 25px 0px;
  height: 42px;
  margin-top: 5px;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-btn {
  font-size: 14px;
  height: 32px;
  padding: 0px 20px;
  border-radius: 6px;
}

.cs-conversation__info {
  float: left;
  white-space: normal;
}

.audio-meter {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);

  width: 5px;
  height: 10%;
  background-color: #ec7d00;
  will-change: height;
  transition-property: height;
  transition-duration: 0.25s;
  border-radius: 8px;
  z-index: 30;
}

.hover-border-like-button {
  border: 1px solid transparent;
  padding: 5px;
  display: inline-block;
  transition: border 0.2s ease-in-out;
}

.hover-border-like-button:hover {
  border: 1px solid gray;
}

.videoContainer {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.jsPanel-content {
  overflow-y: hidden;
}

.li.ant-menu-overflow-item.ant-menu-item.ant-menu-item-selected:hover {
  background-color: rgb(22, 22, 173);
  color: white;
}

.breadcrumb>li:last-child:after {
  border: none;
}

.test123 {
  top: -110px;
  border: 1px solid #ccc;
  padding: 10px;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-layout .ant-layout-sider {
  position: relative;
  min-width: 0;
  background: #000000;
  transition: all 0.2s, background 0s;
}

.dropbtn-user {
  cursor: pointer;
}

.circle-icon {
  padding: 1px;
  color: black;
  align-items: center;
  justify-content: center;
  margin: 15px;
  border-radius: 0%;
  background-color: #eaeaea;
}

.niceBox {
  
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -1px;
  padding: 2px;
display: flex;
  border-radius: 6px;
}

:where(.css-dev-only-do-not-override-1m62vyb).ant-form-item .ant-form-item-label>label {
  color: white;
}

:where(.css-1m62vyb).ant-form-item .ant-form-item-label>label {
  color: white;
}

.ant-tabs-content-holder {
  height: 100% !important;
}

.ant-menu-light.ant-menu-horizontal>.ant-menu-item-selected {
  color: white
}

//tab bouton pour navigauer dans les rooms
.ant-tabs-top>.ant-tabs-nav::before {
  border-bottom: none;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-add {
  background-color: white;

}

.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab {
  padding: 0px 5px;
}

.mainConvo {
    height: calc(100vh - 150px - 90px);
 }

 .mainConvoNopunch {
  height: calc(100vh - 150px - 10px);
}

.react-input-emoji--container {
  border-color: white;
  background-color: white;
}

.react-input-emoji--wrapper {
  width: 100%;
}

.ant-tabs-nav-wrap {
  color: white;
  background-color: transparent;
}

.cs-message__content {
  padding: 1px 5px 5px 5px;
  border-radius: 0;
}

.react-emoji-picker--wrapper {
  left: 10px;
    top: 100px;
  transform: translateY(-410px);
}

.react-input-emoji--container {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.hover-div {
  background-color: #00000012;
  cursor: pointer;
  margin: 5px;
  font-size: 20px;
  padding: 4px;
  border-radius: 4px;
  width: 100%;
  text-align: center;
  transition: background-color 0.3s ease;
}

.hover-div:hover {
  background-color: #e6f7ff; 
}

.custom-input-emoji .react-input-emoji--container {
  min-height: 400px !important;
  max-height: 400px !important; 
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: flex-start;
}

.custom-input-emoji .react-input-emoji--input {
  height: 400px;
}

.custom-input-emoji .react-input-emoji--input {
  min-height: 300px !important;
  max-height: 400px !important; 
  height: 400px !important;   
  overflow-y: auto !important;  
  padding: 10px;
  width: 100%; 
}

.blink-text {
  animation: blink 1s steps(1, end) infinite;
  color: red;
  font-weight: bold;
}

@keyframes blink {
  50% {
      opacity: 0;
  }
}

.textGlow01 { text-shadow: 1px 1px 2px #ff007f, 1px 1px 4px #ff33a1, 1px 1px 6px #ff66c4; }
.textGlow02 { text-shadow: 1px 1px 2px #00ffff, 1px 1px 4px #33ffff, 1px 1px 6px #66ffff; }
.textGlow03 { text-shadow: 1px 1px 2px #00ff00, 1px 1px 4px #33ff33, 1px 1px 6px #66ff66; }
.textGlow04 { text-shadow: 1px 1px 2px #ff4500, 1px 1px 4px #ff6347, 1px 1px 6px #ff7f50; }
.textGlow05 { text-shadow: 1px 1px 2px #8a2be2, 1px 1px 4px #9370db, 1px 1px 6px #ba55d3; }
.textGlow06 { text-shadow: 1px 1px 2px #ffdb4d, 1px 1px 4px #ffea70, 1px 1px 6px #ffff99; }
.textGlow07 { text-shadow: 1px 1px 2px #1e90ff, 1px 1px 4px #4682b4, 1px 1px 6px #5a9bd4; }
.textGlow08 { text-shadow: 1px 1px 2px #32cd32, 1px 1px 4px #3cb371, 1px 1px 6px #66cdaa; }
.textGlow09 { text-shadow: 1px 1px 2px #ff69b4, 1px 1px 4px #ff85c1, 1px 1px 6px #ff99cc; }
.textGlow10 { text-shadow: 1px 1px 2px #b22222, 1px 1px 4px #cd5c5c, 1px 1px 6px #e9967a; }
.textGlow11 { text-shadow: 1px 1px 2px #4b0082, 1px 1px 4px #800080, 1px 1px 6px #9400d3; }
.textGlow12 { text-shadow: 1px 1px 2px #ffb6c1, 1px 1px 4px #ffc0cb, 1px 1px 6px #ffd1dc; }
.textGlow13 { text-shadow: 1px 1px 2px #00ced1, 1px 1px 4px #20b2aa, 1px 1px 6px #40e0d0; }
.textGlow14 { text-shadow: 1px 1px 2px #ff8c00, 1px 1px 4px #ffa500, 1px 1px 6px #ffb347; }
.textGlow15 { text-shadow: 1px 1px 2px #006400, 1px 1px 4px #228b22, 1px 1px 6px #32cd32; }
.textGlow16 { text-shadow: 1px 1px 2px #191970, 1px 1px 4px #000080, 1px 1px 6px #4169e1; }
.textGlow17 { text-shadow: 1px 1px 2px #8b4513, 1px 1px 4px #a0522d, 1px 1px 6px #cd853f; }
.textGlow18 { text-shadow: 1px 1px 2px #dc143c, 1px 1px 4px #ff6347, 1px 1px 6px #ff4500; }
.textGlow19 { text-shadow: 1px 1px 2px #00bfff, 1px 1px 4px #87cefa, 1px 1px 6px #add8e6; }
.textGlow20 { text-shadow: 1px 1px 2px #8fbc8f, 1px 1px 4px #98fb98, 1px 1px 6px #adff2f; }


/* Effet Néon avec Pulsation */
.textGlow01 {
  text-shadow: 0 0 5px #ff007f, 0 0 10px #ff33a1, 0 0 20px #ff66c4, 0 0 30px #ff33a1;
  animation: neonPulse 1.5s infinite alternate;
}

@keyframes neonPulse {
  from {
    text-shadow: 0 0 5px #ff007f, 0 0 10px #ff33a1, 0 0 20px #ff66c4;
  }
  to {
    text-shadow: 0 0 10px #ff007f, 0 0 20px #ff33a1, 0 0 40px #ff66c4, 0 0 60px #ff33a1;
  }
}

/* Effet Dégradé Arc-en-ciel Animé */
.textRainbow {
  background: linear-gradient(90deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffee, #0004ff, #7a00ff, #ff00ff);
  background-size: 400%;
  -webkit-background-clip: text;
  color: transparent;
  animation: rainbow 6s linear infinite;
}

@keyframes rainbow {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Effet de scintillement */
.textBlink {
  animation: blink 1s steps(1, end) infinite;
  color: #ff0000;
  font-weight: bold;
}

@keyframes blink {
  50% {
      opacity: 0;
  }
}

/* Effet Néon Multicolore */
.textGlowMulti {
  text-shadow: 
    0 0 5px #00ffff,
    0 0 10px #33ffff,
    0 0 20px #66ffff,
    0 0 30px #00ff00,
    0 0 40px #33ff33,
    0 0 50px #66ff66;
  animation: glowShift 3s linear infinite;
}

@keyframes glowShift {
  0% { text-shadow: 0 0 5px #00ffff; }
  25% { text-shadow: 0 0 10px #33ffff; }
  50% { text-shadow: 0 0 20px #66ffff; }
  75% { text-shadow: 0 0 30px #00ff00; }
  100% { text-shadow: 0 0 40px #66ff66; }
}


.textNeonClassic {
  color: #ff007f;
  text-shadow: 
    0 0 5px #ff007f, 
    0 0 10px #ff33a1, 
    0 0 20px #ff66c4, 
    0 0 30px #ff33a1, 
    0 0 40px #ff66c4;
  animation: neonPulse 1.5s infinite alternate;
}


.textNeonFire {
  color: #ff4500;
  text-shadow: 
    0 0 5px #ff6347,
    0 0 15px #ff7f50,
    0 0 30px #ff4500,
    0 0 60px #ff6347;
  animation: neonPulseFire 1.5s infinite alternate;
}
@keyframes neonPulseFire {
  from { text-shadow: 0 0 10px #ff6347, 0 0 30px #ff4500; }
  to { text-shadow: 0 0 20px #ff4500, 0 0 50px #ff6347; }
}

.textNeon14 {  text-shadow:1px 1px 1px #027997, 1px 1px 3px #03add8, 1px 1px 5px #03add8; }

.gradient13 { background: linear-gradient(to right,#373B44,#4286f4);color: transparent!important;-webkit-background-clip: text;-webkit-text-fill-color:transparent;text-shadow:0 0 #00000000}



@keyframes gradientMove {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}