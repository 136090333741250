.containerHome {
    padding: 80px;
    background-color: rgb(22, 22, 22);
}

.videochatText {
    color: white;

    text-align: left;
    height: 30px;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
}

.zone1 {
    text-align: left;
    font-size: 3.5rem;
    color: rgb(183, 183, 183);
    font-weight: 700;
}

.zone2Image {
    width: 100%;
}

.smileText {
    color: orange
}

.chatVideoText {
    color: #fb4d3d;
}

.slogan {
    text-align: left;
    font-size: 20px;
    line-height: 30px;
}

.zone3 {

    margin-top: 20px;
}

.zone4 {
    padding-top: 5px;
}

.zone5 {
    padding-left: 5px;
    text-align: left;
    font-size: 18px;
}

.demobtn {
    text-align: left;
    padding-top: 20px;
}

.zone6 {
    padding-top: 15px;
    font-size: 20px;
    text-align: center;
    font-weight: bold;
}

.zone7 {
    font-size: 30px;
    text-align: center;
    font-weight: bold;
}

.zone8 {
    font-weight: bold;
    font-size: 30px;
    text-align: left;
}

.zone9 {
    font-size: 20px;
    text-align: left;
    padding-top: 15px;
}
.zone10{
    padding: 70px;
    border-radius: 15px;
    background-color: rgb(0, 9, 15);
}
.zone11{
    text-align: left;
 
    font-weight: bold;
    color:rgb(183, 183, 183);
    font-size: 25px;
}
.zone12{
    text-align: left;
    padding-top: 15px;
    font-size: 22px;
}
.zone12Image{
    padding-top: 20px;
    height: 100px;
}

.col-list-users-mobile{
    position: relative;
    right: -300px; /* Commence en dehors de l'écran */
    width: fit-content;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: right 1s ease; 
}
.col-list-users-mobile.show {
    right: 0; /* Position finale à l'intérieur de l'écran */
}

.list-item {
    position: relative;
    right: 0;
    width: fit-content;
    margin: 10px 0;
    padding: 10px;
    background-color: cornflowerblue;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: right 0.5s ease;
  }
  
  .slide-in {
    right: -300px; /* Commence en dehors de l'écran */
  }
  
  .show {
    right: 0;
  }

  .user-container {
    position: relative;
    right: -1300px; /* Position initiale en dehors de l'écran */
    transition: right 0.5s ease;
  }
  
  .user-container.show {
    right: 0; /* Position finale à l'écran */
  }

  